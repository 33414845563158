<template>
  <div id="home">
    <div class="social">
      <div class="title">
        Social
        <span style="font-weight: bold;">
          <i class="el-icon-date"></i>
          {{date_show}}
        </span>
      </div>
      <div class="bars">
        <div @click="switch_tabs(0, 0)" :class="barIndex == 0 ? 'act' : ''" v-if="this.show_target.instagram">
          <img src="../assets/imgs/icons/icon_pro1.png"/>
          Instagram
        </div>
        <div @click="switch_tabs(1, 0)" :class="barIndex == 1 ? 'act' : ''" v-if="this.show_target.facebook">
          <img src="../assets/imgs/icons/icon_pro2.png"/>
          Facebook
        </div>
        <div @click="switch_tabs(2, 0)" :class="barIndex == 2 ? 'act' : ''" v-if="this.show_target.linkedin">
          <img src="../assets/imgs/icons/icon_pro3.png"/>
          TikTok
        </div>
        <div @click="switch_tabs(3, 0)" :class="barIndex == 3 ? 'act' : ''" v-if="this.show_target.tiktok">
          <img src="../assets/imgs/icons/icon_pro4.png"/>
          LinkedIn
        </div>
      </div>
      <div class="instagram" v-show="barIndex == 0 && this.show_target.instagram">
        <div class="types">
          <div>
            <span :class="typeIndex == 0 ? 'act' : ''" @click="switch_tabs(0, 0)">Overview</span>
            <span :class="typeIndex == 1 ? 'act' : ''" @click="switch_tabs(0, 1)">Follows</span>
            <span :class="typeIndex == 2 ? 'act' : ''" @click="switch_tabs(0, 2)">Content</span>
          </div>
          <a :href="tabs_url" target="_blank">
            <i class="el-icon-top-right"></i>
            Go to Platform
          </a>
        </div>

        <div class="main" v-show="typeIndex == 0">
          <div class="left">
            <div class="div">
              <h2>Page Overview</h2>
              <h3 v-if="ins_overview_obj.ins_followers != null"><span><countTo :startVal="0" :endVal="ins_overview_obj.ins_followers" :duration="1000"></countTo></span>followers</h3>
              <p v-if="ins_overview_obj.is_show_followers_rate">
                <span class="green">{{ins_overview_obj.ins_followers_rate > 0?"+":""}}{{ins_overview_obj.ins_followers_rate}}%</span>
                Last {{ins_overview_obj.followers_rate_day + 1}} days</p>
            </div>
            <div class="fb_overview_charts_title">
              Instagram views
              <p>
                <countTo style="color:#4A5468;font-size:14px;" v-if="ins_overview_obj.view_number != null" :startVal="0" :endVal="ins_overview_obj.view_number" :duration="1000"></countTo>
                <span v-if="ins_overview_obj.is_show_view_rate && ins_overview_obj.view_rate > 0">+{{ins_overview_obj.view_rate}}%</span>
                <span v-if="ins_overview_obj.is_show_view_rate && ins_overview_obj.view_rate == 0">0%</span>
                <span class="red" v-if="ins_overview_obj.is_show_view_rate && ins_overview_obj.view_rate < 0">{{ins_overview_obj.view_rate}}%</span>
              </p>
            </div>
            <div class="charts" id="ins_view"></div>

            <div class="fb_overview_charts_title">
              Instagram reach
              <p>
                <countTo style="color:#4A5468;font-size:14px;" v-if="ins_overview_obj.reach_number != null" :startVal="0" :endVal="ins_overview_obj.reach_number" :duration="1000"></countTo>
                <span v-if="ins_overview_obj.is_show_reach_rate && ins_overview_obj.reach_rate > 0">+{{ins_overview_obj.reach_rate}}%</span>
                <span v-if="ins_overview_obj.is_show_reach_rate && ins_overview_obj.reach_rate == 0">0%</span>
                <span class="red" v-if="ins_overview_obj.is_show_reach_rate && ins_overview_obj.reach_rate < 0">{{ins_overview_obj.reach_rate}}%</span>
              </p>
            </div>
            <div class="charts" id="ins_reach"></div>

            <div class="fb_overview_charts_title">
              Instagram visits
              <p>
                <countTo style="color:#4A5468;font-size:14px;" v-if="ins_overview_obj.visits_number != null" :startVal="0" :endVal="ins_overview_obj.visits_number" :duration="1000"></countTo>
                <span v-if="ins_overview_obj.is_show_visits_rate && ins_overview_obj.visits_rate > 0">+{{ins_overview_obj.visits_rate}}%</span>
                <span v-if="ins_overview_obj.is_show_visits_rate && ins_overview_obj.visits_rate == 0">0%</span>
                <span class="red" v-if="ins_overview_obj.is_show_visits_rate && ins_overview_obj.visits_rate < 0">{{ins_overview_obj.visits_rate}}%</span>
              </p>
            </div>
            <div class="charts" id="ins_visits"></div>

            <div class="fb_overview_charts_title">
              Instagram followers
              <p>
                <countTo style="color:#4A5468;font-size:14px;" v-if="ins_overview_obj.ins_followers != null" :startVal="0" :endVal="ins_overview_obj.ins_followers" :duration="1000"></countTo>
                <span v-if="ins_overview_obj.is_show_followers_rate && ins_overview_obj.ins_followers_rate > 0">+{{ins_overview_obj.ins_followers_rate}}%</span>
                <span v-if="ins_overview_obj.is_show_followers_rate && ins_overview_obj.ins_followers_rate == 0">0%</span>
                <span class="red" v-if="ins_overview_obj.is_show_followers_rate && ins_overview_obj.ins_followers_rate < 0">{{ins_overview_obj.ins_followers_rate}}%</span>
              </p>
            </div>
            <div class="charts" id="ins_followers"></div>
          </div>
          <div class="right">
            <div class="div">
              <h2>Audicence</h2>
              <p class="title2">These values are based on total followers of your Page or Profile.</p>
            </div>
            <hr/>
            <div class="div">
              <h2>Age and Gender</h2>
              <div style="width:100%;color:#5856D6;font-size: 18px;display: flex;justify-content: flex-start;font-weight: bold;">
                {{ins_overview_obj.age_date_show}}
              </div>
              <div class="genderTuli">
                <div class="item">
                  <b style="background-color: #5856D6;"></b>
                  <a>Men</a>
                  {{ins_overview_obj.men_sum}}%
                </div>
                <div class="item">
                  <b style="background-color: #0BC5EA;"></b>
                  <a>Women</a>
                  {{ins_overview_obj.women_sum}}%
                </div>
              </div>
              <div class="charts" id="ins_basic_dashboard_age"></div>
            </div>
            <hr/>
            <div class="div">
              <h2 class="btnFlex">
                Top Locations
                <div class="btnType">
                  <span :class="{'act':ins_overview_obj.location_bar == 'Cities'}" @click="ins_location_bar_change('Cities')">Cities</span>
                  <span :class="{'act':ins_overview_obj.location_bar == 'Countries'}" @click="ins_location_bar_change('Countries')">Countries</span>
                </div>
              </h2>
              <div style="width:100%;color:#5856D6;font-size: 18px;display: flex;justify-content: flex-start;font-weight: bold;">
                {{ins_overview_obj.location_date_show}}
              </div>
              <div class="locations">
                <div class="item" v-for="(item, index) of ins_overview_obj.location_bar == 'Cities' ? ins_overview_obj.cities : ins_overview_obj.countries" :key="index">
                  <span>{{ins_overview_obj.location_bar == 'Cities' ? item.city_name : item.countries}}</span>
                  <span>
                    <a :style="{width: (ins_overview_obj.location_bar == 'Cities' ? item.widthRate : item.widthRate) + '%'}"></a>
                  </span>
                  <span>{{ins_overview_obj.location_bar == 'Cities' ? item.city_values : item.country_values}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="main followsMain" v-show="typeIndex == 1">
          <div class="div">
            <h2>Follower Breakdown</h2>
            <h3 v-if="ins_followers_obj.final_followers">
              <span>
                <countTo :startVal="0" :endVal="ins_followers_obj.final_followers" :duration="1000"></countTo>
              </span>
            followers
            </h3>
            <p v-if="ins_followers_obj.follow_data && ins_followers_obj.follow_data[0].followers != 0">
              <span v-if="ins_followers_obj.rate == 0">0%</span>
              <span class="green" v-if="ins_followers_obj.rate > 0">+{{ins_followers_obj.rate}}%</span>
              <span class="red" v-if="ins_followers_obj.rate < 0">{{ins_followers_obj.rate}}%</span>
              {{ins_followers_obj.show_time}}
            </p>
          </div>
          <div class="follows">
            <div class="tuli">
              <a>Growth</a>
              <div class="growth">
                <div class="item">
                  <b style="background-color: #5856D6;"></b>
                  <p>Follows</p>
                  <span>{{ins_followers_obj.changes}}</span>
                </div>
              </div>
            </div>
            <div class="charts" id="basic_dashboard_ins"></div>
          </div>
        </div>

        <div class="main" v-show="typeIndex == 2">
          <div class="contentList">
            <div class="listHeader">
              <i class="el-icon-menu"></i>
              Post
            </div>
            <el-empty description="No Data" v-if="!ins_datas_loading && ins_content_obj.datas.length == 0"></el-empty>
            <div class="item" v-for="item,index in ins_content_obj.datas" :key="index" v-if="ins_content_obj.datas.length > 0">
              <div class="img-container" @click="show_edit(item)">
                <img :src="item.listImgSrc"/>
              </div>
              <div class="info" @click="show_edit(item)">
                <span>{{item.time_date}}</span>
                <span>{{item.time}}</span>
                <p>{{item.description}}</p>
              </div>
              <div class="right">
                <span>Likes</span>
                <p>
                  <i style="color:#F56565;" class="el-icon-star-on"></i>{{item.like_count}}
                </p>
              </div>
              <div class="right">
                <span>Comments</span>
                <p>
                  <i class="el-icon-chat-dot-square"></i>{{item.comment_count}}
                </p>
              </div>
              <div class="right">
                <span>Comment count</span>
                <p>
                  <a @click="show_edit(item)">View</a>
                </p>
              </div>
            </div>
            <div class="loading" v-loading="ins_datas_loading" element-loading-text="Loading..." v-if="ins_datas_loading"></div>
            <div class="more-div" v-if="!ins_datas_loading && ins_content_obj.more_available">
              <a class="more" @click="instagram_content_init(1)">More+</a>
            </div>
          </div>
        </div>
      </div>

      <div class="instagram" v-show="barIndex == 1 && this.show_target.facebook">
        <div class="types">
          <div>
            <span :class="typeIndex == 0 ? 'act' : ''" @click="switch_tabs(1, 0)">Overview</span>
            <span :class="typeIndex == 1 ? 'act' : ''" @click="switch_tabs(1, 1)">Follows</span>
            <span :class="typeIndex == 2 ? 'act' : ''" @click="switch_tabs(1, 2)">Content</span>
          </div>
          <a :href="tabs_url" target="_blank">
            <i class="el-icon-top-right"></i>
            Go to Platform
          </a>
        </div>
        <div class="main" v-show="typeIndex == 0">
          <div class="left">
            <div class="div">
              <h2>Page Overview</h2>
              <h3 v-if="fb_overview_obj.fb_followers != null"><span><countTo :startVal="0" :endVal="fb_overview_obj.fb_followers" :duration="1000"></countTo></span>followers</h3>
              <p v-if="fb_overview_obj.is_show_followers_rate">
                <span class="green">{{fb_overview_obj.fb_followers_rate > 0?"+":""}}{{fb_overview_obj.fb_followers_rate}}%</span>
                Last {{fb_overview_obj.followers_rate_day + 1}} days</p>
            </div>
            <div class="fb_overview_charts_title">
              Facebook views
              <p>
                <countTo style="color:#4A5468;font-size:14px;" v-if="fb_overview_obj.view_number != null" :startVal="0" :endVal="fb_overview_obj.view_number" :duration="1000"></countTo>
                <span v-if="fb_overview_obj.is_show_view_rate && fb_overview_obj.view_rate > 0">+{{fb_overview_obj.view_rate}}%</span>
                <span v-if="fb_overview_obj.is_show_view_rate && fb_overview_obj.view_rate == 0">0%</span>
                <span class="red" v-if="fb_overview_obj.is_show_view_rate && fb_overview_obj.view_rate < 0">{{fb_overview_obj.view_rate}}%</span>
              </p>
            </div>
            <div class="charts" id="fb_view"></div>

            <div class="fb_overview_charts_title">
              Facebook reach
              <p>
                <countTo style="color:#4A5468;font-size:14px;" v-if="fb_overview_obj.reach_number != null" :startVal="0" :endVal="fb_overview_obj.reach_number" :duration="1000"></countTo>
                <span v-if="fb_overview_obj.is_show_reach_rate && fb_overview_obj.reach_rate > 0">+{{fb_overview_obj.reach_rate}}%</span>
                <span v-if="fb_overview_obj.is_show_reach_rate && fb_overview_obj.reach_rate == 0">0%</span>
                <span class="red" v-if="fb_overview_obj.is_show_reach_rate && fb_overview_obj.reach_rate < 0">{{fb_overview_obj.reach_rate}}%</span>
              </p>
            </div>
            <div class="charts" id="fb_reach"></div>

            <div class="fb_overview_charts_title">
              Facebook visits
              <p>
                <countTo style="color:#4A5468;font-size:14px;" v-if="fb_overview_obj.visits_number != null" :startVal="0" :endVal="fb_overview_obj.visits_number" :duration="1000"></countTo>
                <span v-if="fb_overview_obj.is_show_visits_rate && fb_overview_obj.visits_rate > 0">+{{fb_overview_obj.visits_rate}}%</span>
                <span v-if="fb_overview_obj.is_show_visits_rate && fb_overview_obj.visits_rate == 0">0%</span>
                <span class="red" v-if="fb_overview_obj.is_show_visits_rate && fb_overview_obj.visits_rate < 0">{{fb_overview_obj.visits_rate}}%</span>
              </p>
            </div>
            <div class="charts" id="fb_visits"></div>

            <div class="fb_overview_charts_title">
              Facebook followers
              <p>
                <countTo style="color:#4A5468;font-size:14px;" v-if="fb_overview_obj.fb_followers != null" :startVal="0" :endVal="fb_overview_obj.fb_followers" :duration="1000"></countTo>
                <span v-if="fb_overview_obj.is_show_followers_rate && fb_overview_obj.fb_followers_rate > 0">+{{fb_overview_obj.fb_followers_rate}}%</span>
                <span v-if="fb_overview_obj.is_show_followers_rate && fb_overview_obj.fb_followers_rate == 0">0%</span>
                <span class="red" v-if="fb_overview_obj.is_show_followers_rate && fb_overview_obj.fb_followers_rate < 0">{{fb_overview_obj.fb_followers_rate}}%</span>
              </p>
            </div>
            <div class="charts" id="fb_followers"></div>
          </div>
          <div class="right">
            <div class="div">
              <h2>Audicence</h2>
              <p class="title2">These values are based on total followers of your Page or Profile.</p>
            </div>
            <hr/>
            <div class="div">
              <h2>Age and Gender</h2>
              <div style="width:100%;color:#5856D6;font-size: 18px;display: flex;justify-content: flex-start;font-weight: bold;">
                {{fb_overview_obj.age_date_show}}
              </div>
              <div class="genderTuli">
                <div class="item">
                  <b style="background-color: #5856D6;"></b>
                  <a>Men</a>
                  {{fb_overview_obj.men_sum}}%
                </div>
                <div class="item">
                  <b style="background-color: #0BC5EA;"></b>
                  <a>Women</a>
                  {{fb_overview_obj.women_sum}}%
                </div>
              </div>
              <div class="charts" id="basic_dashboard2"></div>
            </div>
            <hr/>
            <div class="div">
              <h2 class="btnFlex">
                Top Locations
                <div class="btnType">
                  <span :class="{'act':fb_overview_obj.location_bar == 'Cities'}" @click="fb_location_bar_change('Cities')">Cities</span>
                  <span :class="{'act':fb_overview_obj.location_bar == 'Countries'}" @click="fb_location_bar_change('Countries')">Countries</span>
                </div>
              </h2>
              <div style="width:100%;color:#5856D6;font-size: 18px;display: flex;justify-content: flex-start;font-weight: bold;">
                {{fb_overview_obj.location_date_show}}
              </div>
              <div class="locations">
                <div class="item" v-for="(item, index) of fb_overview_obj.location_bar == 'Cities' ? fb_overview_obj.cities : fb_overview_obj.countries" :key="index">
                  <span>{{fb_overview_obj.location_bar == 'Cities' ? item.city_name : item.countries}}</span>
                  <span>
                    <a :style="{width: (fb_overview_obj.location_bar == 'Cities' ? item.widthRate : item.widthRate) + '%'}"></a>
                  </span>
                  <span>{{fb_overview_obj.location_bar == 'Cities' ? item.city_values : item.country_values}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="main followsMain" v-show="typeIndex == 1">
          <div class="div">
            <h2>Follower Breakdown</h2>
            <h3>
              <countTo style="color:#4A5468;font-size:14px;" v-if="fb_followers_obj.final_followers"
              :startVal="0" :endVal="fb_followers_obj.final_followers" :duration="1000"></countTo>
              followers
            </h3>
            <p v-if="fb_followers_obj.is_show_rate">
              <span class="green" v-if="fb_followers_obj.rate > 0">+{{fb_followers_obj.rate}}%</span>
              <span class="green" v-if="fb_followers_obj.rate == 0">0%</span>
              <span class="red" v-if="fb_followers_obj.rate < 0">{{fb_followers_obj.rate}}%</span>
              {{fb_followers_obj.show_date}}
            </p>
          </div>
          <div class="follows">
            <div class="tuli">
              <a>Growth</a>
              <div class="growth">
                <div class="item" @click="initEchart">
                  <b style="background-color: #48BB78;"></b>
                  <p>Follows</p>
                  <span>{{fb_followers_obj.changes}}</span>
                </div>
              </div>
            </div>
            <div class="charts" id="basic_dashboard_1_1"></div>
          </div>
        </div>
        <div class="main" v-show="typeIndex == 2">
          <div class="contentList">
            <div class="listHeader">
              <i class="el-icon-menu"></i>
              Post
            </div>
            <el-empty description="No Data" v-if="!fb_datas_loading && fb_content_obj.datas.length == 0"></el-empty>
            <div class="item" v-for="item,index in fb_content_obj.datas" :key="index" v-if="fb_content_obj.datas.length > 0">
              <div class="img-container" @click="show_edit_fb(item)">
                <img :src="item.listImgSrc" v-if="item.listImgSrc"/>
                <img v-if="!item.listImgSrc" src="../assets/imgs/logo_top_m.png"/>
              </div>
              <div class="info" @click="show_edit_fb(item)">
                <span>{{item.time_date}}</span>
                <span>{{item.time}}</span>
                <p>{{item.post_description}}</p>
              </div>
              <div class="right">
                <span>Reactions</span>
                <p>
                  <i style="color:#F56565;" class="el-icon-star-on"></i>{{item.likes}}
                </p>
              </div>
              <div class="right">
                <span>Comments</span>
                <p>
                  <i class="el-icon-chat-dot-square"></i>{{item.comments_count}}
                </p>
              </div>
              <div class="right">
                <p>
                  <a @click="show_edit_fb(item)">View</a>
                </p>
              </div>
            </div>
            <div class="loading" v-loading="fb_datas_loading" element-loading-text="Loading..." v-if="fb_datas_loading"></div>
            <div class="more-div" v-if="!fb_datas_loading && fb_content_obj.more_available">
              <a class="more" @click="facebook_content_init(1)">More+</a>
            </div>
          </div>
        </div>
      </div>

      <div class="instagram" v-show="(barIndex == 2 && this.show_target.linkedin) || (barIndex == 3 && this.show_target.tiktok)">
        <div class="types">
          <div>
            <span :class="typeIndex == 0 ? 'act' : ''" @click="typeIndex = 0">Overview</span>
            <span :class="typeIndex == 1 ? 'act' : ''" @click="typeIndex = 1">Follows</span>
            <span :class="typeIndex == 2 ? 'act' : ''" @click="typeIndex = 2">Content</span>
          </div>
          <a>
            <i class="el-icon-top-right"></i>
            Go to Platform
          </a>
        </div>
        <div class="main tiktopMain" v-show="typeIndex == 0">
          <div class="tiktopInfo">
            <h2>Analytcs<a>More info<i class="el-icon-more"></i></a></h2>
            <div class="list">
              <div class="item act">
                New followers
                <p>
                  2
                  <b class="green">1 (-100.00)</b>
                  <i class="el-icon-top green"></i>
                </p>
              </div>
              <div class="item">
                New likes
                <p>
                  0
                  <b>0 (0.00%)</b>
                </p>
              </div>
              <div class="item">
                New profile visits
                <p>
                  2
                  <b class="green">1 (-100.00)</b>
                  <i class="el-icon-top green"></i>
                </p>
              </div>
              <div class="item">
                New vedio views
                <p>
                  2
                  <b class="green">1 (-100.00)</b>
                  <i class="el-icon-top green"></i>
                </p>
              </div>
            </div>
          </div>
          <div class="tiktopInfo">
            <h2>Engagement<a>More info<i class="el-icon-more"></i></a></h2>
            <div class="list list3">
              <div class="item">
                Followers
                <p>
                  2
                  <b class="green">1 (-100.00)</b>
                  <i class="el-icon-top green"></i>
                </p>
              </div>
              <div class="item">
                New likes
                <p>
                  0
                  <b>0 (0.00%)</b>
                </p>
              </div>
              <div class="item">
                New profile visits
                <p>
                  2
                  <b class="green">1 (-100.00)</b>
                  <i class="el-icon-top green"></i>
                </p>
              </div>
            </div>
          </div>
          <div class="tiktopInfo">
            <h2>Profile views</h2>
            <div id="profileViewsCharts"></div>
          </div>
          <div class="tiktopInfo">
            <h2>Video views</h2>
            <div class="videoTitle">
              <h4>4,453,225<span>views</span></h4>
              <h5><span class="green">+2.4%</span>Last 7 days</h5>
            </div>
            <div id="videoViewsCharts"></div>
          </div>
        </div>
        <div class="main followsMain" v-show="typeIndex == 1">
          <div class="div">
            <h2>Follower Breakdown</h2>
            <h3><span>1,777</span>followers</h3>
            <p><span class="green">+2.4%</span>Dec 30 - Jan 5</p>
          </div>
          <div class="follows">
            <div class="tuli">
              <a>Growth</a>
              <div class="growth">
                <div class="item act" @click="initEchart">
                  <b style="background-color: #5856D6;"></b>
                  <p>Overall</p>
                  <span>53</span>
                </div>
                <div class="item" @click="initEchart">
                  <b style="background-color: #48BB78;"></b>
                  <p>Follows</p>
                  <span>61</span>
                </div>
                <div class="item" @click="initEchart">
                  <b style="background-color: #F56565;"></b>
                  <p>Unfollows</p>
                  <span>8</span>
                </div>
              </div>
            </div>
            <div class="charts" id="basic_dashboard3"></div>
          </div>
        </div>
        <div class="main" v-show="typeIndex == 2">
          <div class="contentList">
            <div class="listHeader">
              <i class="el-icon-menu"></i>
              Post
            </div>
            <div class="item" v-for="item,index in 5" :key="index">
              <img src="https://img0.baidu.com/it/u=3452548144,1566320356&fm=253&fmt=auto&app=138&f=JPEG?w=714&h=500"/>
              <div class="info">
                <span>may 6, 2024</span>
                <span>10:43 AM</span>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority sheets containing There are many variations of passages of Lorem Ipsum available, but the majority sheets containing There are many varia...</p>
              </div>
              <div class="right">
                <span>Likes</span>
                <p>
                  <i style="color:#F56565;" class="el-icon-star-on"></i>6,404
                </p>
              </div>
              <div class="right">
                <span>Comments</span>
                <p>
                  <i class="el-icon-chat-dot-square"></i>49
                </p>
              </div>
              <div class="right">
                <span>Comment count</span>
                <p>
                  <a>View</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <show ref="viewForm"></show>
    <fb-show ref="fbViewForm"></fb-show>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import Vue from 'vue';
  import { swiper, swiperSlide } from "vue-awesome-swiper";
  import { getActionApi } from '@/network/manageApi';
  import * as echarts from 'echarts';
  import 'swiper/css/swiper.css'
  import countTo from 'vue-count-to';
  import moment from 'moment-timezone';
  import Show from './socialMedia/show'
  import FbShow from './socialMedia/FbShow'
  import timeZoneData from '@/json/timeZone';
  export default {
    name: 'SocialMedia',
    components: {
      swiper, swiperSlide,countTo,Show,FbShow
    },
    data() {
      return {
        barIndex:0,
        typeIndex:0,
        swiperOptions:{
          observer: true,
          observeParents: true,
          spaceBetween:20
        },
        show_target: {},
        date_show: "",
        top_post_data_ins: [],
        ins_from_date: "",
        ins_to_date: "",
        ins_followers_obj: {},
        show_ins_followers_number: 0,
        tabs_url: '',
        ins_overview_obj: {},
        ins_content_obj: {datas: [], next_token: '', more_available: false},
        ins_datas_loading: false,
        fb_from_date: "",
        fb_to_date: "",
        fb_overview_obj: {},
        fb_followers_obj: {},
        fb_content_obj: {datas: [], next_token: '', more_available: false},
        fb_datas_loading: false,
        chart_list: []
      }
    },
    computed: {
      ...mapGetters(['time_zone'])
    },
    mounted(){
      // this.initEchart3()
      // this.initEchart4()
    },
    created(){
      this.init_page()
    },
    methods:{
      show_edit(item) {
        this.$refs.viewForm.init(item)
      },
      show_edit_fb(item) {
        this.$refs.fbViewForm.init(item)
      },
      async init_page() {
        //tabs
        let res = await getActionApi("/socialmedia/activate")
        this.show_target = res.data
        if(this.show_target.instagram) {
          this.switch_tabs(0, 0)
          return;
        } else if(this.show_target.facebook) {
          this.switch_tabs(1, 0)
          return;
        } else if(this.show_target.linkedin) {
          this.switch_tabs(2, 0)
          return;
        } else if(this.show_target.tiktok) {
          this.switch_tabs(3, 0)
          return;
        } else {
          const that = this
          this.$messageBox('There are no social media accounts available for viewing, please contact us', 'Tip', {
            confirmButtonText: 'ok',
            type: 'warning'
          }).then(() => {
            that.$router.push({ path: '/support' })
          })
        }
      },
      switch_tabs(bar, item) {
        this.barIndex = bar
        this.typeIndex = item
        if(this.barIndex == 0 && this.typeIndex == 0) {
          this.instagram_overview_init()
        } else if(this.barIndex == 0 && this.typeIndex == 1) {
          this.instagram_follows_init()
        } else if(this.barIndex == 0 && this.typeIndex == 2) {
          this.instagram_content_init(0)
        } else if (this.barIndex == 1 && this.typeIndex == 0) {
          this.facebook_overview_init()
        } else if (this.barIndex == 1 && this.typeIndex == 1) {
          this.facebook_followers_init()
        } else if (this.barIndex == 1 && this.typeIndex == 2) {
          this.facebook_content_init(0)
        }
      },
      instagram_content_init(type) {
        if(this.ins_datas_loading) {
          return;
        }
        let param = {}
        let flag = false
        if(type != 0) {
          if(!this.ins_content_obj.more_available) {
            return;
          }
          param.next_token = this.ins_content_obj.next_token
          flag = true
        } else {
            if(this.ins_content_obj.datas == undefined || this.ins_content_obj.datas.length == 0) {
              flag = true
            }
        }
        if(flag) {
          this.ins_datas_loading = true
          getActionApi("/socialmedia/instagram/contents",param).then(res => {
              let datas = []
              for(let item of res.data.contents) {
                let data = {}
                data.content_id = item.content_id
                data.content_code = item.content_code
                data.url = item.url
                data.like_count = item.like_count
                data.comment_count = item.comment_count
                data.video_play_count = item.video_play_count
                data.description = item.description
                data.device_timestamp = item.device_timestamp
                //time zone
                let momentTime = moment.unix(data.device_timestamp)

                let convertedTime = momentTime.tz(timeZoneData[this.time_zone])
                data.time_date = convertedTime.format("MMM DD,yyyy")
                data.time = convertedTime.format("hh:mm A")
                data.listImgSrc = item.images.candidates[0].url
                let detailResources = []
                let rs = {}
                if(item.carousel_media != null) {
                  for(let img of item.carousel_media) {
                    rs = {}
                    rs.type = 'img'
                    rs.url = img.display_uri
                    detailResources.push(rs)
                  }
                }
                if(item.video != null) {
                  rs = {}
                  rs.type = 'video'
                  rs.url = item.video[0].url
                  detailResources.push(rs)
                }
                if(detailResources.length == 0) {
                  rs.type = 'img'
                  rs.url = item.images.candidates[0].url
                  detailResources.push(rs)
                }
                data.detailResources = detailResources
                datas.push(data)
              }
            if(type == 0) {
              this.ins_content_obj.datas = []
            }
            this.ins_content_obj.datas.push(...datas)
            this.ins_content_obj.next_token = res.data.next_token
            this.ins_content_obj.more_available = res.data.more_available
          }).catch((err) => {
          }).finally((res) => {
            this.ins_datas_loading = false
          });
        }
      },
      facebook_content_init(type) {
        if(this.fb_datas_loading) {
          return;
        }
        let param = {}
        let flag = false
        if(type != 0) {
          if(!this.fb_content_obj.more_available) {
            return;
          }
          param.next_token = this.fb_content_obj.next_token
          flag = true
        } else {
            if(this.fb_content_obj.datas == undefined || this.fb_content_obj.datas.length == 0) {
              flag = true
            }
        }
        if(flag) {
          this.fb_datas_loading = true
          getActionApi("/socialmedia/facebook/contents",param).then(res => {
              let datas = []
              for(let item of res.data.data) {
                let data = {}
                data.facebook_content_key = item.facebook_content_key
                data.facebook_content_url = item.facebook_content_url
                data.likes = item.likes
                data.post_description = item.post_description
                data.comments_count = item.comments_count
                data.post_datetime = item.post_datetime
                //time zone
                let momentTime = moment(data.post_datetime)
                // let convertedTime = momentTime.tz(timeZoneData[this.time_zone])
                data.time_date = momentTime.format("MMM DD,yyyy")
                data.time = momentTime.format("hh:mm A")
                data.listImgSrc = null
                let detailResources = []
                let rs = {}
                if(item.images != null) {
                  rs.type = 'img'
                  rs.url = item.images.uri
                  data.listImgSrc = item.images.uri
                  detailResources.push(rs)
                }
                if(item.carousel_images != null) {
                  for(let img of item.carousel_images) {
                    rs = {}
                    rs.type = 'img'
                    rs.url = img.image_file_uri
                    detailResources.push(rs)
                    if(data.listImgSrc == null)
                      data.listImgSrc = img.image_file_uri
                  }
                }
                if(item.videos != null) {
                  rs = {}
                  rs.type = 'video'
                  rs.url = item.videos.video_hd_file
                  detailResources.push(rs)
                }
                data.detailResources = detailResources
                datas.push(data)
              }
            if(type == 0) {
              this.fb_content_obj.datas = []
            }
            this.fb_content_obj.datas.push(...datas)
            this.fb_content_obj.next_token = res.data.cursor
            if(res.data.cursor != null) {
              this.fb_content_obj.more_available = true
            } else {
              this.fb_content_obj.more_available = false
            }
          }).catch((err) => {
          }).finally((res) => {
            this.fb_datas_loading = false
          });
        }
      },
      instagram_overview_init() {
        this.ins_overview_obj = {};
        this.ins_location_bar_change('Cities');
        getActionApi("/socialmedia/instagram/overview1").then(res => {
          let view_data = res.data.view
          view_data.sort((a, b) => {
            let dateA = new Date(a.records_date);
            let dateB = new Date(b.records_date);
            return dateA - dateB; // order asc
          })
          let reach_data = res.data.reach
          reach_data.sort((a, b) => {
            let dateA = new Date(a.records_date);
            let dateB = new Date(b.records_date);
            return dateA - dateB; // order asc
          })
          let visit_data = res.data.visits
          visit_data.sort((a, b) => {
            let dateA = new Date(a.records_date);
            let dateB = new Date(b.records_date);
            return dateA - dateB; // order asc
          })
          let followers_data = res.data.followers
          followers_data.sort((a, b) => {
            let dateA = new Date(a.record_date);
            let dateB = new Date(b.record_date);
            return dateA - dateB; // order asc
          })
          let cities_data = res.data.cities
          cities_data.sort((a, b) => {
            return b.city_values - a.city_values; // order desc
          })
          let countries_data = res.data.countries
          countries_data.sort((a, b) => {
            return b.country_values - a.country_values; // order desc
          })


          if(res.data.view) {
            this.date_show = moment(res.data.view[0].records_date).format("MMM DD") + " - " + moment(res.data.view[res.data.view.length - 1].records_date).format("MMM DD")
          } else {
            this.date_show = ""
          }

          //followers
          let ins_followers = 0
          if(followers_data) {
            ins_followers = followers_data[followers_data.length - 1].followers
          }
          let is_show_followers_rate = false
          let ins_followers_rate = 0
          let followers_rate_day = 0
          if(followers_data.length >= 2) {
            this.ins_from_date = followers_data[0].record_date
            this.ins_to_date = followers_data[followers_data.length - 1].record_date
            if(followers_data[0].followers != 0) {
                is_show_followers_rate = true
                ins_followers_rate = Math.floor((ins_followers - followers_data[0].followers)/followers_data[0].followers * 10000)/100
                followers_rate_day = moment(followers_data[followers_data.length - 1].record_date).diff(moment(followers_data[0].record_date), "days")
            }
          }
          this.$set(this.ins_overview_obj, 'ins_followers', ins_followers);
          this.$set(this.ins_overview_obj, 'is_show_followers_rate', is_show_followers_rate);
          this.$set(this.ins_overview_obj, 'ins_followers_rate', ins_followers_rate);
          this.$set(this.ins_overview_obj, 'followers_rate_day', followers_rate_day);
          //view
          let view_number = 0
          let view_rate = 0
          let is_show_view_rate = false
          if(view_data) {
            view_number = view_data[view_data.length - 1].view_number
            if(view_data.length >= 2) {
              if(view_data[0].view_number != 0) {
                view_rate = Math.floor((view_number - view_data[0].view_number)/view_data[0].view_number * 10000)/100
                is_show_view_rate = true
              }
            }
          }
          this.$set(this.ins_overview_obj, 'view_number', view_number);
          this.$set(this.ins_overview_obj, 'view_rate', view_rate);
          this.$set(this.ins_overview_obj, 'is_show_view_rate', is_show_view_rate);

          let reach_number = 0
          let reach_rate = 0
          let is_show_reach_rate = false
          if(reach_data) {
            reach_number = reach_data[reach_data.length - 1].reach_number
            if(reach_data.length >= 2) {
              if(reach_data[0].reach_number != 0) {
                reach_rate = Math.floor((reach_number - reach_data[0].reach_number)/reach_data[0].reach_number * 10000)/100
                is_show_reach_rate = true
              }
            }
          }
          this.$set(this.ins_overview_obj, 'reach_number', reach_number);
          this.$set(this.ins_overview_obj, 'reach_rate', reach_rate);
          this.$set(this.ins_overview_obj, 'is_show_reach_rate', is_show_reach_rate);


          let visits_number = 0
          let visits_rate = 0
          let is_show_visits_rate = false
          if(visit_data) {
            visits_number = visit_data[visit_data.length - 1].visit_number
            if(visit_data.length >= 2) {
              if(visit_data[0].visit_number != 0) {
                visits_rate = Math.floor((visits_number - visit_data[0].visit_number)/visit_data[0].visit_number * 10000)/100
                is_show_visits_rate = true
              }
            }
          }
          this.$set(this.ins_overview_obj, 'visits_number', visits_number);
          this.$set(this.ins_overview_obj, 'visits_rate', visits_rate);
          this.$set(this.ins_overview_obj, 'is_show_visits_rate', is_show_visits_rate);

          let age_data = res.data.age
          let age_date_show = ""
          let location_date_show = ""
          if(age_data) {
              age_date_show = moment(age_data.men.from_date).format("MMM DD") + " - " + moment(age_data.men.to_date).format("MMM DD")
          }
          if(cities_data) {
              location_date_show = moment(cities_data[0].from_date).format("MMM DD") + " - " + moment(cities_data[0].to_date).format("MMM DD")
          }
          this.$set(this.ins_overview_obj, 'age_date_show', age_date_show);
          this.$set(this.ins_overview_obj, 'location_date_show', location_date_show);

          //city rate %- city name
          for(let item of cities_data) {
            item.city_name = item.cities.split(",")[0]
            item.widthRate = (item.city_values/cities_data[0].city_values) * 100
          }
          //country rate
          for(let item of countries_data) {
            item.widthRate = (item.country_values/countries_data[0].country_values) * 100
          }
          this.$set(this.ins_overview_obj, 'men_sum', res.data.age.men.sum);
          this.$set(this.ins_overview_obj, 'women_sum', res.data.age.women.sum);
          this.$set(this.ins_overview_obj, 'cities', cities_data);
          this.$set(this.ins_overview_obj, 'countries', countries_data);
          this.initEchart2(res.data.age,"ins_basic_dashboard_age");
          this.initEchart5('ins_view', view_data.map(v => v.records_date), view_data.map(v => v.view_number), "view");
          this.initEchart5('ins_reach', reach_data.map(v => v.records_date), reach_data.map(v => v.reach_number), "reach");
          this.initEchart5('ins_visits', visit_data.map(v => v.records_date), visit_data.map(v => v.visit_number), "visits");
          this.initEchart5('ins_followers', followers_data.map(v => v.record_date), followers_data.map(v => v.followers), "followers");
        })

        getActionApi("/socialmedia/instagram/information").then(res => {
          this.tabs_url = res.data[0].url
        })
      },
      facebook_followers_init() {
        let param = {from_date: this.fb_from_date, to_date: this.fb_to_date}
        getActionApi("/socialmedia/facebook/followers",param).then(res => {
          let followers_data = res.data.follow_data
          followers_data.sort((a, b) => {
            let dateA = new Date(a.record_date);
            let dateB = new Date(b.record_date);
            return dateA - dateB; // order asc
          })
          let is_show_rate = false
          let rate = 0
          let show_date = ""
          if(followers_data && followers_data.length >= 2) {
            show_date = moment(followers_data[0].record_date).format("MMM DD") + " - "
            + moment(followers_data[followers_data.length - 1].record_date).format("MMM DD")
            if(followers_data[0].followers != 0) {
              is_show_rate = true
              rate = Math.floor((res.data.final_followers - followers_data[0].followers)/followers_data[0].followers * 10000)/100
            }
          }
          this.$set(this.fb_followers_obj, 'changes', res.data.changes);
          this.$set(this.fb_followers_obj, 'final_followers', res.data.final_followers);
          this.$set(this.fb_followers_obj, 'is_show_rate', is_show_rate);
          this.$set(this.fb_followers_obj, 'rate', rate);
          this.$set(this.fb_followers_obj, 'show_date', show_date);
          this.initEchart5("basic_dashboard_1_1", followers_data.map(v => v.record_date), followers_data.map(v => v.followers), "Followers")
        })
      },
      facebook_overview_init() {
        this.fb_overview_obj = {};
        this.fb_location_bar_change('Cities');
        getActionApi("/socialmedia/facebook/overview").then(res => {
          let view_data = res.data.view
          view_data.sort((a, b) => {
            let dateA = new Date(a.records_date);
            let dateB = new Date(b.records_date);
            return dateA - dateB; // order asc
          })
          let reach_data = res.data.reach
          reach_data.sort((a, b) => {
            let dateA = new Date(a.records_date);
            let dateB = new Date(b.records_date);
            return dateA - dateB; // order asc
          })
          let visit_data = res.data.visits
          visit_data.sort((a, b) => {
            let dateA = new Date(a.records_date);
            let dateB = new Date(b.records_date);
            return dateA - dateB; // order asc
          })
          let followers_data = res.data.followers
          followers_data.sort((a, b) => {
            let dateA = new Date(a.record_date);
            let dateB = new Date(b.record_date);
            return dateA - dateB; // order asc
          })
          let cities_data = res.data.cities
          cities_data.sort((a, b) => {
            return b.city_values - a.city_values; // order desc
          })
          let countries_data = res.data.countries
          countries_data.sort((a, b) => {
            return b.country_values - a.country_values; // order desc
          })


          if(res.data.view) {
            this.date_show = moment(res.data.view[0].records_date).format("MMM DD") + " - " + moment(res.data.view[res.data.view.length - 1].records_date).format("MMM DD")
          } else {
            this.date_show = ""
          }

          //followers
          let fb_followers = 0
          if(followers_data) {
            fb_followers = followers_data[followers_data.length - 1].followers
          }
          let is_show_followers_rate = false
          let fb_followers_rate = 0
          let followers_rate_day = 0
          if(followers_data.length >= 2) {
            this.fb_from_date = followers_data[0].record_date
            this.fb_to_date = followers_data[followers_data.length - 1].record_date
            if(followers_data[0].followers != 0) {
                is_show_followers_rate = true
                fb_followers_rate = Math.floor((fb_followers - followers_data[0].followers)/followers_data[0].followers * 10000)/100
                followers_rate_day = moment(followers_data[followers_data.length - 1].record_date).diff(moment(followers_data[0].record_date), "days")
            }
          }
          this.$set(this.fb_overview_obj, 'fb_followers', fb_followers);
          this.$set(this.fb_overview_obj, 'is_show_followers_rate', is_show_followers_rate);
          this.$set(this.fb_overview_obj, 'fb_followers_rate', fb_followers_rate);
          this.$set(this.fb_overview_obj, 'followers_rate_day', followers_rate_day);
          //view
          let view_number = 0
          let view_rate = 0
          let is_show_view_rate = false
          if(view_data) {
            view_number = view_data[view_data.length - 1].view_number
            if(view_data.length >= 2) {
              if(view_data[0].view_number != 0) {
                view_rate = Math.floor((view_number - view_data[0].view_number)/view_data[0].view_number * 10000)/100
                is_show_view_rate = true
              }
            }
          }
          this.$set(this.fb_overview_obj, 'view_number', view_number);
          this.$set(this.fb_overview_obj, 'view_rate', view_rate);
          this.$set(this.fb_overview_obj, 'is_show_view_rate', is_show_view_rate);

          let reach_number = 0
          let reach_rate = 0
          let is_show_reach_rate = false
          if(reach_data) {
            reach_number = reach_data[reach_data.length - 1].reach_number
            if(reach_data.length >= 2) {
              if(reach_data[0].reach_number != 0) {
                reach_rate = Math.floor((reach_number - reach_data[0].reach_number)/reach_data[0].reach_number * 10000)/100
                is_show_reach_rate = true
              }
            }
          }
          this.$set(this.fb_overview_obj, 'reach_number', reach_number);
          this.$set(this.fb_overview_obj, 'reach_rate', reach_rate);
          this.$set(this.fb_overview_obj, 'is_show_reach_rate', is_show_reach_rate);


          let visits_number = 0
          let visits_rate = 0
          let is_show_visits_rate = false
          if(visit_data) {
            visits_number = visit_data[visit_data.length - 1].visit_number
            if(visit_data.length >= 2) {
              if(visit_data[0].visit_number != 0) {
                visits_rate = Math.floor((visits_number - visit_data[0].visit_number)/visit_data[0].visit_number * 10000)/100
                is_show_visits_rate = true
              }
            }
          }
          this.$set(this.fb_overview_obj, 'visits_number', visits_number);
          this.$set(this.fb_overview_obj, 'visits_rate', visits_rate);
          this.$set(this.fb_overview_obj, 'is_show_visits_rate', is_show_visits_rate);

          let age_data = res.data.age
          let age_date_show = ""
          let location_date_show = ""
          if(age_data) {
              age_date_show = moment(age_data.men.from_date).format("MMM DD") + " - " + moment(age_data.men.to_date).format("MMM DD")
          }
          if(cities_data) {
              location_date_show = moment(cities_data[0].from_date).format("MMM DD") + " - " + moment(cities_data[0].to_date).format("MMM DD")
          }
          this.$set(this.fb_overview_obj, 'age_date_show', age_date_show);
          this.$set(this.fb_overview_obj, 'location_date_show', location_date_show);

          //city rate %- city name
          for(let item of cities_data) {
            item.city_name = item.cities.split(",")[0]
            item.widthRate = (item.city_values/cities_data[0].city_values) * 100
          }
          //country rate
          for(let item of countries_data) {
            item.widthRate = (item.country_values/countries_data[0].country_values) * 100
          }
          this.$set(this.fb_overview_obj, 'men_sum', res.data.age.men.sum);
          this.$set(this.fb_overview_obj, 'women_sum', res.data.age.women.sum);
          this.$set(this.fb_overview_obj, 'cities', cities_data);
          this.$set(this.fb_overview_obj, 'countries', countries_data);
          this.initEchart2(res.data.age, 'basic_dashboard2');
          this.initEchart5('fb_view', view_data.map(v => v.records_date), view_data.map(v => v.view_number), "view");
          this.initEchart5('fb_reach', reach_data.map(v => v.records_date), reach_data.map(v => v.reach_number), "reach");
          this.initEchart5('fb_visits', visit_data.map(v => v.records_date), visit_data.map(v => v.visit_number), "visits");
          this.initEchart5('fb_followers', followers_data.map(v => v.record_date), followers_data.map(v => v.followers), "followers");
        })
        getActionApi("/socialmedia/facebook/information").then(res => {
          this.tabs_url = res.data[0].facebook_url;
        })
      },
      fb_location_bar_change(location) {
        this.$set(this.fb_overview_obj, 'location_bar', location);
      },
      ins_location_bar_change(location) {
        this.$set(this.ins_overview_obj, 'location_bar', location);
      },
      async instagram_follows_init() {
        let param = {}
        param.from_date = this.ins_from_date
        param.to_date = this.ins_to_date
        let res = await getActionApi("/socialmedia/instagram/followers", param)
        this.ins_followers_obj = res.data

        let rate = 0
        if(this.ins_followers_obj.follow_data[0].followers != 0) {
          rate = Math.floor((this.ins_followers_obj.changes/this.ins_followers_obj.follow_data[0].followers)* 10000)/100
        }
        this.ins_followers_obj.rate = rate
        this.ins_followers_obj.show_time = moment(this.ins_followers_obj.follow_data[0].record_date).format("MMM DD") + " - " + moment(this.ins_followers_obj.follow_data[this.ins_followers_obj.follow_data.length - 1].record_date).format("MMM DD")

        let x_datas = []
        let y_datas = []
        for(let item of this.ins_followers_obj.follow_data) {
          x_datas.push(item.record_date)
          y_datas.push(item.followers)
        }
        this.initEchart(x_datas, y_datas)
      },
      initEchart(x_datas, y_datas){
        const chartDom = document.getElementById('basic_dashboard_ins');
        const myChart = this.$echarts.init(chartDom);
        const option = {
          color: [
            '#5856D6',
          ],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          grid: {
            left: '4%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: x_datas
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: 'Followers',
              type: 'line',
              emphasis: {
                focus: 'series'
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(88, 86, 214, 1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(88, 86, 214, 0)'
                  }
                ])
              },
              data: y_datas
            }
          ]
        };
        myChart.setOption(option);
        let obj = {option, myChart}
        this.chart_list.push(obj)
        let that = this
        window.onresize = function(){
            that.resize_chart()
        }
      },
      initEchart2(age, id) {
        const chartDom = document.getElementById(id);
        const myChart = this.$echarts.init(chartDom);
        const option = {
          color: [
            '#5856D6',
            '#76E4F7'
          ],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          grid: {
            left: '6%',
            right: '2%',
            bottom: '8%'
          },
          xAxis: [
            {
              type: 'category',
              data: ['18-24', '25-34', '35-44', '45-54', '55-64', '65+']
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: 'Men',
              type: 'bar',
              barWidth:'20px',
              data: [age.men.age_18_24, age.men.age_25_34, age.men.age_35_44, age.men.age_45_54, age.men.age_55_64, age.men.age_65]
            },
            {
              name: 'Women',
              type: 'bar',
              barWidth:'20px',
              data: [age.women.age_18_24, age.women.age_25_34, age.women.age_35_44, age.women.age_45_54, age.women.age_55_64, age.women.age_65]
            }
          ]
        };
        myChart.setOption(option);
        let obj = {option, myChart}
        this.chart_list.push(obj)
        let that = this
        window.onresize = function(){
            that.resize_chart()
        }
      },
      initEchart3(){
        var chartDom = document.getElementById('profileViewsCharts');
        var myChart = this.$echarts.init(chartDom);
        var option = {
          color: [
            '#5856D6',
            '#F56565'
          ],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          legend: {
            data: ['Total', 'Targeted'],
            bottom:'2%'
          },
          grid: {
            left: '0%',
            right: '0%',
            bottom: '12%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: 'Total',
              type: 'line',
              emphasis: {
                focus: 'series'
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(88, 86, 214, 1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(88, 86, 214, 0)'
                  }
                ])
              },
              data: [120, 132, 101, 134, 90, 230, 210]
            },
            {
              name: 'Targeted',
              type: 'line',
              emphasis: {
                focus: 'series'
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(245, 101, 101, 1)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(245, 101, 101, 0)'
                  }
                ])
              },
              data: [20, 20, 20, 20, 20, 20, 20]
            }
          ]
        };
        myChart.setOption(option);
        let obj = {option, myChart}
        this.chart_list.push(obj)
        let that = this
        window.onresize = function(){
            that.resize_chart()
        }
      },
      initEchart4(){
        var chartDom = document.getElementById('videoViewsCharts');
        var myChart = this.$echarts.init(chartDom);
        var option = {
          color: [
            '#5856D6'
          ],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          grid: {
            left: '0%',
            right: '0%',
            bottom: '2%',
            containLabel: true
          },
          xAxis: {
              type: 'category',
              data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed']
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                data: [120, 200, 150, 80, 70, 110, 130, 260, 60, 160],
                type: 'bar',
                barWidth:'40px'
              }
            ]
        };
        myChart.setOption(option);
        let obj = {option, myChart}
        this.chart_list.push(obj)
        let that = this
        window.onresize = function(){
            that.resize_chart()
        }
      },
      initEchart5(id, x_datas, y_datas, series_name){
        const chartDom = document.getElementById(id);
        const myChart = this.$echarts.init(chartDom);
        const option = {
          color: [
            '#5856D6',
          ],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          grid: {
            left: '4%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: x_datas
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: series_name,
              type: 'line',
              emphasis: {
                focus: 'series'
              },
              data: y_datas
            }
          ]
        };
        myChart.setOption(option);
        let obj = {option, myChart}
        this.chart_list.push(obj)
        let that = this
        window.onresize = function(){
            that.resize_chart()
        }
      },
      resize_chart() {
        for(let item of this.chart_list) {
          item.myChart.setOption(item.option)
          item.myChart.resize()
        }
      }
    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  #home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}

  .filter{background-color: #ffffff;box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);border-radius: 16px;padding: 20px 30px;}
  .filter h3{color:#2D3648;font-size: 20px;margin-bottom:30px;}
  .filter .item{display: flex;justify-content: space-between;align-items: flex-end;margin-bottom:10px;}
  .filter .item div{display: flex;}
  .filter .item p{display: flex;flex-direction: column;color: #A0ABC0;margin-right: 20px;}
  .filter .item input{width: 260px;height:36px;border-radius: 5px;border: 1px solid #EDF0F7;margin-top:10px;font-size: 14px;padding:0 20px;color:#717D96;}
  .filter .item select{width: 260px;height:36px;border-radius: 5px;border: 1px solid #EDF0F7;margin-top:10px;font-size: 14px;padding:0 20px;color:#717D96;}
  .filter .item h2{width: 80px;height: 40px;display: flex;justify-content: center;align-items: center;cursor: pointer;background-color: #5856D6;border-radius: 6px;color:#ffffff;font-size: 14px;font-weight: normal;}
  .social{display: flex;flex-direction: column;background-color: #ffffff;box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);border-radius: 16px;padding:20px;}
  .social .title{font-size: 20px;color: #2D3648;font-weight: bold;display: flex;justify-content: space-between;align-items: center;}
  .social .title span{color:#5856D6;font-weight:normal;font-size: 18px;}
  .social .title span i{margin-right: 6px;}
  .social .bars{display: flex;margin:20px 0;}
  .social .bars div{border-top:1px solid #ffffff;border-bottom:1px solid #CBD5E0;cursor: pointer;width: 160px;height:50px;display: flex;justify-content: center;align-items: center;font-size: 18px;color: #2D3748;border-radius: 6px 6px 0 0;}
  .social .bars .act{border:1px solid #CBD5E0;border-bottom-color: #ffffff;color: #5856D6;}
  .social .bars div img{width: 16px;height:16px;margin-right: 10px;}
  .instagram .types{display: flex;align-items: center;justify-content: space-between;margin:10px 0;}
  .instagram .types div{display: flex;}
  .instagram .types span{line-height: 40px;padding:0 10px;border-radius: 20px;border:1px solid #CBD5E0;margin-right: 10px;cursor: pointer;}
  .instagram .types .act{background-color: #ECECFF;border-color: #ECECFF;color: #5856D6;}
  .instagram .types a{width: 170px;height: 40px;cursor: pointer;background-color: #5856D6;color:#ffffff;border-radius: 6px;display: flex;justify-content: center;align-items: center;}
  .instagram .types a i{margin-right: 10px;}
  .fb_overview_charts_title{width: 100%;display: flex;justify-content: space-between;align-items: center;font-weight: bold;}
  .fb_overview_charts_title p{display: flex;align-items: center;font-size: 20px;}
  .fb_overview_charts_title p span{font-size: 16px;color: green;}
  .main{display: flex;justify-content: center;}
  .main .left{width: 50%;border-right: 1px solid #CBD5E0;padding:30px;display: flex;flex-direction: column;align-items: center;}
  .main h3{display: flex;align-items: center;color: #717D96;font-size: 18px;}
  .main h3 span{font-size: 26px;color: #5856D6;margin-right: 6px;}
  .main p{font-size: 14px;color: #4A5468;line-height: 1.6;}
  .main p span{margin:0 10px;}
  .main .left .list{width: 100%;}
  .title2{width: 100%;color: #717D96 !important;font-size: 18px !important;}
  .genderTuli{align-self: flex-start;padding-left:5px;}
  .genderTuli .item{display: flex;align-items: center;height: 40px;font-size: 14px;color: #4A5468;font-weight: bold;}
  .genderTuli .item b{width: 12px;height: 12px;margin-right: 10px;}
  .genderTuli .item a{width: 80px;color: #718096;font-weight: normal;}
  .btnFlex{display: flex;justify-content: space-between;width: 100%;align-items: center;}
  .btnType{display: flex;background-color: #E5E5EA;border-radius: 10px;padding: 3px;}
  .btnType span{width: 120px;height:30px;display: flex;font-weight: normal;justify-content: center;align-items: center;font-size: 14px;color: #21272A;border-radius: 7px;cursor: pointer;}
  .btnType .act{color: #5856D6;font-weight: bold;background-color: #ffffff;}
  .locations{width: 100%;margin-top:20px;}
  .locations .item{display: flex;align-items: center;height:40px;color: #4A5468;font-size: 14px;}
  .locations .item span:nth-child(1){width: 180px;flex-shrink: 0;}
  .locations .item span:nth-child(2){width: 100%;background-color: #EDF0F7;height: 20px;border-radius: 10px;display: flex;overflow: hidden;}
  .locations .item span:nth-child(3){width: 50px;flex-shrink: 0;margin-left:20px;text-align: right;}
  .locations .item span a{background-color: #5856D6;border-radius: 10px 0 0 10px;height: 20px;}
  hr{background-color: #CBD5E0;border: none;height: 1px;margin:10px 0;}
  .main .left .list .item{height:50px;display: flex;padding:0 20px;justify-content: space-between;color: #2D3648;align-items: center;font-size: 14px;}
  .main .left .list .itemSon{display: flex;justify-content: space-between;align-items: center;padding: 10px 40px 10px 70px;font-size:14px;color:#2D3648;}
  .main .left .list .item a{display: flex;flex-direction: column;font-size: 20px;color: #2D3748;align-items: flex-end;line-height: 1;}
  .main .left .list .item a b{font-size: 14px;margin-top:10px;color: #A0ABC0;}
  .green{color:#48BB78 !important;}
  .red{color:#F56565 !important;}
  .main .div{display: flex;flex-direction: column;align-items: center;justify-content: center;padding:30px 0;}
  .main h2{color:#2D3748;font-size: 26px;line-height: 1;margin-bottom:20px;}
  .main .right{width: 50%;padding:30px;}
  .main .right h2{align-self: flex-start;}
  .main .right .line{display: flex;width: 100%;flex-direction: column;align-items: center;}
  .main .right .line .item{display: flex;width: 100%;align-items: center;height: 40px;}
  .main .right .line .item span{color:#4A5468;font-size: 14px;flex-shrink: 0;}
  .main .right .line .item .a{width: 100%;flex-shrink: 1;margin:0 30px;background-color: #EDF0F7;height: 3px;display: flex;align-items: center;border-radius: 3px;}
  .main .right .line .item .a a{background-color: #5856D6;height:3px;border-radius: 3px;}
  .main .right .line .summary{display: flex;align-items: center;justify-content: center;color: #718096;font-size: 14px;margin:20px 0 50px 0;}
  .main .right .line .summary b{color: #5856D6;margin-right: 10px;font-size: 30px;font-weight: normal;}
  .main .right .based{display: flex;color:#2D3648;align-items: center;justify-content: space-between;font-weight: bold;margin-bottom:20px;}
  .main .right .based a{font-size: 14px;color: #5856D6;font-weight: normal;cursor: pointer;}
  .main .right .imgs{overflow: hidden;width: calc((100vw - 450px) / 2) !important;}
  .main .right .imgs .item{text-align:center;font-weight: bold;color: #4A5468;align-items: center;width:120px !important;}
  .main .right .imgs .item .imgItem{border-radius: 8px;width:120px;height:120px;margin-bottom:10px;flex-shrink: 0;background-size: cover;background-color: #ededed;}
  .followsMain{flex-direction: column;}
  .follows{width: 100%;display: flex;align-items: center;}
  .follows .tuli{width: 260px;flex-shrink: 0;margin-right: 30px;height: 200px;border: 1px solid #CBD5E0;border-radius: 16px;padding:30px;display: flex;flex-direction: column;}
  .follows .tuli a{font-size: 14px;color: #2D3748;}
  .follows .tuli .growth .item{height: 35px;display: flex;align-items: center;font-size: 14px;color: #717D96;cursor: pointer;}
  .follows .tuli .growth .act{color: #000000;}
  .follows .tuli .growth .item b{width: 10px;height: 10px;border-radius: 50%;margin-right: 10px;flex-shrink: 0;}
  .follows .tuli .growth .item p{width: 100%;}
  .follows .tuli .growth .item span{flex-shrink: 0;}
  .tiktopMain{display: grid;gap:30px;grid-template-columns: 1fr;}
  .tiktopMain h2{display: flex;justify-content: space-between;width: 100%;margin-top:20px;align-items: center;}
  .tiktopMain h2 a{font-size:14px;color:#717D96;font-weight: normal;cursor: pointer;}
  .tiktopMain h2 a i{color:#717D96;font-size: 14px;margin-left:10px;}
  .tiktopInfo{width: 100%;}
  .tiktopInfo .list3{grid-template-columns: 1fr 1fr 1fr !important;}
  .tiktopInfo .list2{grid-template-columns: 1fr 1fr !important;}
  .tiktopInfo .list{display: grid;gap:30px;grid-template-columns: 1fr 1fr 1fr 1fr;}
  .tiktopInfo .list .item{color:#A0AEC0;font-size:12px;box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);padding:20px;display: flex;flex-direction: column;border-radius: 16px;cursor: pointer;}
  .tiktopInfo .list .item p{margin-top: 10px;display: flex;align-items: center;font-size: 20px;color: #2D3748;font-weight: bold;}
  .tiktopInfo .list .item p b{font-size: 14px;color: #717D96;margin-left:10px;}
  .tiktopInfo .list .item p i{margin-left:10px;font-size: 20px;font-weight: bold;}
  .tiktopInfo .list .act{background-color: #5856D6;color:#ffffff !important;}
  .tiktopInfo .list .act p{color:#ffffff !important;}
  #profileViewsCharts{height:300px;}
  #videoViewsCharts{height:300px;}
  .videoTitle{display: flex;flex-direction: column;align-items: center;}
  .videoTitle h4{font-size: 26px;color: #5856D6;display: flex;align-items: center;}
  .videoTitle h4 span{font-size: 18px;font-weight: normal;color: #717D96;margin-left:10px;}
  .videoTitle h5{color:#4A5468;font-size: 14px;font-weight: normal;margin-top:10px;}
  .videoTitle h5 span{margin-right: 10px;}
  .charts{width: 100%;height: 300px;}
  .contentList{width: 100%;border-top:1px solid #CBD5E0;margin-top:20px;}
  .contentList .listHeader{background-color: #F2F4F8;padding:0 20px;height: 40px;display: flex;align-items: center;color: #4A5468;font-size: 14px;}
  .contentList .listHeader i{margin-right: 10px;color: #717D96;}
  .contentList .item{display: flex;align-items: center;border-bottom: 1px solid #EDF2F7;}
  .contentList .item:last-child{border-bottom-width: 0;}
  .contentList .item .img-container {cursor: pointer;width: 120px;height: 120px;flex-shrink: 0;display: flex;justify-content: center;align-items: center;overflow: hidden;}
  .contentList .item img{max-height: 120px;}
  .contentList .item .info{cursor: pointer;width: 100%;margin:0 10px;display: flex;flex-direction: column;font-size: 14px;line-height: 1.5;}
  .contentList .item .info p{overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;white-space: normal;}
  .contentList .item .right{flex-shrink: 0;width:120px;font-size: 14px;padding:10px 0;display: flex;flex-direction: column;}
  .contentList .item .right span{color: #A0ABC0;margin-bottom:10px;}
  .contentList .item .right p{color: #2D3748;font-size: 16px;font-weight: bold;display: flex;align-items: center;}
  .contentList .item .right i{margin-right: 10px;font-size: 20px;}
  .contentList .item .right a{cursor: pointer;color: #4299E1;font-weight: normal;}
  .detailBtn{border:1px solid #5856D6;border-radius: 6px;display: flex;align-items: center;justify-content: center;cursor: pointer;color:#5856D6;height:40px;width: 100%;margin:20px 0;}
  .loading {width: 100%;height: 60px;margin-bottom: 20px;}
  .more-div {display: flex;justify-content: center;align-items: center;padding: 20px 0;}
  .more-div .more {width: 80px;height: 36px;cursor: pointer;background-color: #5856D6;color: #ffffff;border-radius: 6px;display: flex;justify-content: center;align-items: center;font-size: 14px;}
  @media (max-width: 768px) {
    #home{padding:10px;}
    .filter .item div{flex-direction: column;}
    .filter .item select{width: 200px;}
    .filter .item input{width: 200px;}
    .social .bars div{width: 100%;flex-direction: column;font-size: 14px;}
    .tiktopInfo .list3{grid-template-columns: 1fr !important;}
    .tiktopInfo .list2{grid-template-columns: 1fr !important;}
    .tiktopInfo .list{display: grid;gap:30px;grid-template-columns: 1fr;}
    .main{display: grid;grid-template-columns: 1fr;width: 100%;}
    .main .left{border-right-width:0;width: 100%;padding: 10px;}
    .main .right{width:calc(100vw - 60px);padding: 10px;}
    .social .bars div img{margin-right: 0;}
    .follows{flex-direction: column;}
    .contentList .item{flex-wrap: wrap;padding-top:10px;}
    .contentList .item .img-container {width: 100px;height: 100px;flex-shrink: 0;display: flex;justify-content: center;align-items: center;overflow: hidden;}
    .contentList .item img{max-height: 100px;}
    .contentList .item .info{width: calc(100vw - 180px);flex-shrink: 0;}
    .contentList .item .right:nth-child(3){margin-right: 50vw;}
    .instagram .types{flex-direction: column;}
    .instagram .types div{margin-bottom:20px;}
    .btnFlex{flex-direction: column;}
    .btnType{margin-top:10px;}
    .locations .item span:nth-child(1){width: 130px;font-size: 12px;}
    .locations .item span:nth-child(3){font-size: 12px;width: 30px;margin-left:10px;}
    .follows .tuli {width: 100%;margin-right:0;}
    .main .right .imgs{overflow: hidden;width: calc(100vw - 80px) !important;}
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .filter .item select{width: 220px;}
    .filter .item input{width: 220px;}
    .tiktopInfo .list3{grid-template-columns: 1fr 1fr !important;}
    .tiktopInfo .list{display: grid;gap:30px;grid-template-columns: 1fr 1fr;}
    .main{display: grid;grid-template-columns: 1fr;width: 100%;}
    .main .left{border-right-width:0;width: 100%;}
    .main .right{width:calc(100vw - 200px);}
    .follows{flex-direction: column;}
    .follows .tuli {width: 100%;margin-right:0;}
    .main .right .imgs{overflow: hidden;width: calc(100vw - 250px) !important;}
  }
</style>
