<template>
  <div id="home">
    <div class="title">
      Do you have any questions?
    </div>
    <div class="div" v-loading="loading">
      <div>
        First Name
        <input v-model="first_name" placeholder="First Name"/>
      </div>
      <div>
        Last Name
        <input v-model="last_name" placeholder="Last Name"/>
      </div>
      <div>
        Phone Number
        <input v-model="phone_number" placeholder="Phone Number"/>
      </div>
      <div>
        E-Mail
        <input v-model="email" placeholder="E-Mail"/>
      </div>
      <div>
        Note
        <textarea v-model="inquiry" placeholder="Note"></textarea>
      </div>
      <div class="btnDiv">
        <span class="btn" @click="submitForm">Submit</span>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import * as echarts from 'echarts';
import { postActionApi } from '@/network/manageApi';
  export default {
    name: 'Support',
    components: {

    },
    data() {
      return {
        loading:false,
        first_name:'',
        last_name:'',
        phone_number:'',
        email:'',
        inquiry:''
      }
    },
    computed: {

    },
    created(){

    },
    methods:{
      submitForm(){
        if(this.first_name == '')
          this.$message.error('First Name is required')
        else if(this.last_name == '')
          this.$message.error('Last Name is required')
        else if(this.phone_number == '')
          this.$message.error('Phone Number is required')
        else if(this.email == '')
          this.$message.error('E-Mail is required')
        else if(this.inquiry == '')
          this.$message.error('Note is required')
        else{
          this.loading = true
          const params = {
            first_name:this.first_name,
            last_name:this.last_name,
            phone_number:this.phone_number,
            email:this.email,
            inquiry:this.inquiry
          }
          postActionApi('/inquiry/user-form',params).then((res) => {
            this.$message({
              message: 'Information saved successfully',
              type: 'success'
            });
            this.first_name = ''
            this.last_name = ''
            this.phone_number = ''
            this.email = ''
            this.inquiry = ''
          }).catch((err) => {
            this.$message.error(err.response.data.details.data.message)
          }).finally((res) => {
            this.loading = false
          });
        }
      }
    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  #home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}
  .div{background-color: #ffffff;padding: 30px;display: flex;flex-direction: column;box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);border-radius: 16px;}
  .title{color:#2D3648;font-size: 20px;font-weight: bold;display: flex;justify-content: space-between;align-items: center;}
  .btn{height:40px;width:86px;cursor: pointer;background-color: #5856D6;color:#ffffff;border-radius: 6px;font-size: 14px;display: flex;align-items: center;justify-content: center;}
  .div div{display: flex;flex-direction: column;color:#A0ABC0;font-weight: bold;padding-bottom:20px;}
  .div div input{border-radius: 6px;border:1px solid #E2E8F0;height:48px;padding:0 20px;margin-top:10px;}
  .div div textarea{border-radius: 6px;border:1px solid #E2E8F0;height:92px;padding:20px;margin-top:10px;}
  .btnDiv{display: flex;width:100%;align-items: flex-end;}
  @media (max-width: 768px) {

  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {

  }
</style>
