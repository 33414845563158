<template>
  <div id="register">
    fb

    <div @click="checkLoginState">Click Login Facebook</div>
    <div id="status"></div>
    <button @click="startCamera">OPEN CAMERA</button>
    <button @click="stopCamera">CLOSE CAMERA</button>
    <button @click="flip_over">FLIP OVER</button>
    <div style="border:3px red solid;width: 40vw;height:600px;position: absolute;border-radius: 30vw;margin-left: 20%;margin-top: 100px;"></div>
    <video ref="video" autoplay webkit-playsinline="true" playsinline="true" x5-video-player-type="h5-page" ></video>



  </div>
</template>

<script>
  import { mapGetters,mapActions } from 'vuex'
  import Vue from 'vue'


  // window.fbAsyncInit = function() {
  //   FB.init({
  //     appId      : '2242675222734655',
  //     cookie     : true,
  //     xfbml      : true,
  //     version    : 'v21.0'
  //   });
  //   // FB.getLoginStatus(function(response) {
  //   //   statusChangeCallback(response);
  //   // });
  // };

  // (function(d, s, id) {
  //     var js, fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) return;
  //     js = d.createElement(s); js.id = id;
  //     js.src = "https://connect.facebook.net/en_US/sdk.js";
  //     fjs.parentNode.insertBefore(js, fjs);
  //   }(document, 'script', 'facebook-jssdk'));

  export default {
    name: 'FBLogin',
    components: {
    },
    data() {
      return {
        cameraStream: null,
        flip_over_flag: true
      }
    },
    mounted() {
    },
    computed: {

    },
    created() {

    },
    methods: {
      async flip_over() {
        this.stopCamera()
        if(this.flip_over_flag) {
          this.startBackCamera()
        } else {
          this.startCamera()
        }
        this.flip_over_flag = !this.flip_over_flag
      },
      async startBackCamera() {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: { facingMode: { exact: "environment" } },
          });
          this.cameraStream = stream;
          this.$refs.video.srcObject = stream;
        } catch (error) {
          console.error(error);
        }
      },
      async startCamera() {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: true,
          });
          this.cameraStream = stream;
          this.$refs.video.srcObject = stream;
        } catch (error) {
          console.error(error);
        }
      },
      stopCamera() {
        if (this.cameraStream) {
          this.cameraStream.getTracks()[0].stop();
          this.cameraStream = null;
        }
      },
      // testAPI() {
      //   console.log('Welcome!  Fetching your information.... ');
      //   FB.api('/me', function(response) {
      //     console.log('Successful login for: ' + response.name);
      //     document.getElementById('status').innerHTML =
      //       'Thanks for logging in, ' + response.name + '!';
      //   });
      // },
      // statusChangeCallback(response) {
      //   console.log('statusChangeCallback');
      //   console.log(response);
      //   if (response.status === 'connected') {
      //     this.testAPI();
      //   } else {
      //     FB.login(function(response){
      //         if (response.status === 'connected') {
      //           document.getElementById('status').innerHTML = 'My token is' + response.authResponse.accessToken;
      //         } else {
      //           document.getElementById('status').innerHTML = 'User cancels login';
      //         }
      //     });
      //   }
      // },
      // checkLoginState() {
      //   let that = this
      //   FB.getLoginStatus(function(response) {
      //     that.statusChangeCallback(response);
      //   });
      // }
    }
  }
</script>

<style scoped>
</style>
