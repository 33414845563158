<template>
  <div id="home">
    <div class="title">
      Reset password
    </div>
    <div class="div" v-loading="loading">
      <div>
        Password
        <input v-model="password" type="password" placeholder="Password"/>
      </div>
      <div>
        Confirm Password
        <input v-model="password2" type="password" placeholder="Confirm Password"/>
      </div>
      <div class="btnDiv">
        <span class="btn" @click="submitForm">Submit</span>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import * as echarts from 'echarts';
  import { putActionApi } from '@/network/manageApi';
  export default {
    name: 'ResetPassword',
    components: {

    },
    data() {
      return {
        loading:false,
        password:'',
        password2:''
      }
    },
    computed: {

    },
    created(){

    },
    methods:{
      submitForm(){
        let reg= /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#$%^&*()_+<>?:"{},.\/\\;'[\]])[A-Za-z\d`~!@#$%^&*()_+<>?:"{},.\/\\;'[\]]{6,18}$/;
        if(this.password == '')
          this.$message.error('Password is required')
        else if(!reg.test(this.password))
          this.$message.error('combinations of alphanumeric and special symbols within 6 to 18 characters')
        else if(this.password2 == '')
          this.$message.error('Confirm Password is required')
        else if(this.password != this.password2)
          this.$message.error('Passwords do not match.')
        else{
          this.loading = true
          const params = {
            password:this.password
          }
          putActionApi('/user-management/my-information/password/change',params).then((res) => {
            this.$message({
              message: 'Reset password successfully',
              type: 'success'
            });
            this.password = ''
            this.password2 = ''
          }).catch((err) => {
            this.$message.error(err.response.data.details.data.message)
          }).finally((res) => {
            this.loading = false
          });
        }
      }
    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  #home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}
  .div{background-color: #ffffff;padding: 30px;display: flex;flex-direction: column;box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);border-radius: 16px;}
  .title{color:#2D3648;font-size: 20px;font-weight: bold;display: flex;justify-content: space-between;align-items: center;}
  .btn{height:40px;width:86px;cursor: pointer;background-color: #5856D6;color:#ffffff;border-radius: 6px;font-size: 14px;display: flex;align-items: center;justify-content: center;}
  .div div{display: flex;flex-direction: column;color:#A0ABC0;font-weight: bold;padding-bottom:20px;}
  .div div input{border-radius: 6px;border:1px solid #E2E8F0;height:48px;padding:0 20px;margin-top:10px;}
  .div div textarea{border-radius: 6px;border:1px solid #E2E8F0;height:92px;padding:20px;margin-top:10px;}
  .btnDiv{display: flex;width:100%;align-items: flex-end;}
  @media (max-width: 768px) {

  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {

  }
</style>
