<template>
  <div id="home">

    <div class="div">
      <div class="form">
        <div class="title">
          Account Information
        </div>
        <el-upload
          class="headImage"
          ref="upload"
          :accept="accept"
          :action="fileAction"
          :headers="headers"
          :on-success="handleChange"
          :multiple="false"
          :show-file-list="false">
          <div class="edit">
            <span>Edit</span>
          </div>
          <img :src="getImg(model.public_files_uuid)"/>
        </el-upload>
        <div>
          <p>Address 1<span>*</span></p>
          <input placeholder="Street address" v-model="model.address1" autocomplete="off"/>
        </div>
        <div>
          Address 2
          <input placeholder="Apt, Suite, Building (optional)" v-model="model.address2" autocomplete="off"/>
        </div>
        <div>
          <p>City<span>*</span></p>
          <input placeholder="City" v-model="model.city" autocomplete="off"/>
        </div>
        <div>
          <p>State<span>*</span></p>
          <select v-model="model.state" autocomplete="off">
            <option v-for="item,index in state" :key="index" :value="item">{{item}}</option>
          </select>
        </div>
        <div>
          <p>Zip code<span>*</span></p>
          <input placeholder="Zip code" autocomplete="off" v-model="model.zip_code"/>
        </div>

        <div class="title">
          Contact Information
        </div>
        <div>
          <p>Phone number<span>*</span></p>
          <input placeholder="Phone number" autocomplete="off" v-model="model.phone_number"/>
        </div>
        <div v-if="isChange" style="align-items: flex-end;padding-bottom: 0;">
          <span class="saveBtn" @click="saveInfo"><i class="el-icon-check"></i>Confirm</span>
        </div>
      </div>
      <!-- <div class="card">
        <div class="title">
          Social Link
        </div>
        <div class="cardList">
          <div class="item">
            <img src="../assets/imgs/icons/icon_pro2.png"/>
            <span>Facebook</span>
            <div class="userInfo">
              <a>
                <i class="el-icon-user-solid"></i>
              </a>
              ieowi@google.com
            </div>
            <div class="btn">
              <img src="../assets/imgs/icons/icon7.png"/>
              Link with Facebook
            </div>
          </div>
          <div class="item">
            <img src="../assets/imgs/icons/icon_pro6.png"/>
            <span>Google</span>
            <div class="userInfo">
              <a>
                <i class="el-icon-user-solid"></i>
              </a>
              ieowi@google.com
            </div>
            <div class="btn">
              <img src="../assets/imgs/icons/icon_pro6.png"/>
              Link with Google
            </div>
          </div>
          <div class="item gray">
            <img src="../assets/imgs/icons/icon_pro1.png"/>
            <span>Instagram</span>
            <div class="userInfo">
              <p>No account</p>
            </div>
            <div class="btn">
              <img src="../assets/imgs/icons/icon_pro1.png"/>
              Link with Instagram
            </div>
          </div>
          <div class="item gray">
            <img src="../assets/imgs/icons/icon_pro5.png"/>
            <span>Yelp</span>
            <div class="userInfo">
              <p>No account</p>
            </div>
            <div class="btn">
              <img src="../assets/imgs/icons/icon_pro5.png"/>
              Link with Yelp
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import { ACCESS_TOKEN } from '@/store/mutation-types'
  import { getActionApi, putActionApi } from '@/network/manageApi';
  export default {
    name: 'AccountSetting',
    components: {

    },
    data() {
      return {
        imgData:null,
        state:['AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD',
        'MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY'],
        headers: {Authorization: this.$ls.get(ACCESS_TOKEN)},
        accept: 'image/*',
        fileAction:`${process.env.VUE_APP_BASE_API}/public-file/upload/public`,
        oldModel:{},
        model:{},
        isChange:false
      }
    },
    watch:{
      model:{
        deep:true,
        immediate:true,
        handler (newValue) {
          this.isChange = JSON.stringify(newValue) != JSON.stringify(this.oldModel)
        }
      }
    },
    created(){
      this.queryInfo()
    },
    methods:{
      getImg(url){
        return `${process.env.VUE_APP_BASE_API}/public-file/public/`+url
      },
      queryInfo(){
        getActionApi('/user-management/my-information').then(res => {
          this.oldModel = {...res.data}
          this.model = {...res.data}
          if(this.model.public_files_uuid)
            this.$store.commit('SET_HEADIMGURL', this.model.public_files_uuid)
        })
      },
      handleChange(response) {
        this.$set(this.model,'public_files_uuid',response.data.public_files_uuid)
      },
      saveInfo(){
        if(!this.model.address1){
          this.$message.error('Address1 is required')
        }else if(!this.model.city){
          this.$message.error('City is required')
        }else if(!this.model.state){
          this.$message.error('State is required')
        }else if(!this.model.zip_code){
          this.$message.error('Zip code is required')
        }else if(!this.model.phone_number){
          this.$message.error('Phone number is required')
        }else{
          this.loading = true
          let params = {
            address1:this.model.address1,
            address2:this.model.address2,
            city:this.model.city,
            state:this.model.state,
            zip_code:this.model.zip_code,
            phone_number:this.model.phone_number,
            public_files_uuid:this.model.public_files_uuid
          }
          putActionApi('/user-management/my-information',params).then(res => {
            this.$message({
              message: 'Information saved successfully',
              type: 'success'
            });
            this.queryInfo()
          }).catch((err) => {
            this.$message.error(err.response.data.details.data.message)
          }).finally((res) => {
            this.loading = false
          });
        }
      }
    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  #home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}
  .div{display: flex;}
  .title{color:#2D3648 !important;font-size: 20px;font-weight: bold;}
  .btn{height:40px;width:86px;cursor: pointer;background-color: #5856D6;color:#ffffff;border-radius: 6px;font-size: 14px;display: flex;align-items: center;justify-content: center;}
  .form{display: flex;width:100%;flex-direction: column;background-color: #ffffff;padding: 30px;flex-direction: column;box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);border-radius: 16px;}
  .form .flex{display: grid;grid-template-columns:1fr 1fr;gap: 20px;padding-bottom: 0;}
  .form div{display: flex;flex-direction: column;color:#A0ABC0;font-weight: bold;padding-bottom:20px;}
  .form div span{color:#E53E3E;font-size: 14px;margin-left:5px;}
  .form div input{border-radius: 6px;border:1px solid #E2E8F0;height:40px;padding:0 20px;margin-top:10px;}
  .form div select{border-radius: 6px;border:1px solid #E2E8F0;height:40px;padding:0 20px;margin-top:10px;box-sizing: border-box;}
  /* .card{width: 450px;margin-left:30px;flex-shrink: 0;padding-top:30px;}
  .cardList{border:1px solid #CBD5E0;border-radius: 16px;box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);overflow: hidden;margin-top:20px;}
  .cardList .item{border-bottom: 1px solid rgba(226, 232, 240, 1);padding:20px;background-color: #ffffff;display: flex;flex-direction: column;align-items: center;}
  .cardList .item img{width: 30px;height: 30px;}
  .cardList .item span{font-size: 12px;color:#717D96;margin:3px;}
  .cardList .item .userInfo{display: flex;align-items: center;height:32px;color:#4A5468;font-size: 14px;font-weight: bold;}
  .cardList .item .userInfo a{width: 32px;height: 32px;background-color: #A0AEC0;display: flex;align-items: center;color:#ffffff;font-size: 22px;justify-content: center;border-radius: 50%;margin-right: 10px;}
  .cardList .item .userInfo p{color: #A0ABC0;font-weight: normal;}
  .cardList .item:last-child{border-bottom-width: 0;}
  .cardList .item .btn{width: 100%;display: flex;align-items: center;justify-content: center;margin-top:10px;box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.084);}
  .cardList .item .btn img{width: 23px;height: 23px;margin-right: 20px;}
  .cardList .item:nth-child(1) .btn{background-color: #1877F2;}
  .cardList .item:nth-child(2) .btn{background-color: #ffffff;color: rgba(0, 0, 0, 0.54);}
  .cardList .item:nth-child(3) .btn{background-color: #4A5468;}
  .cardList .item:nth-child(4) .btn{background-color: #ffffff;color: rgba(0, 0, 0, 0.54);}
  .cardList .gray{background-color: rgba(0, 0, 0, 0.02);} */
  .headImage{display: flex;width: 76px;}
  .headImage .edit{display: none;padding-bottom:0;width: 76px;height: 76px;position: absolute;justify-content: center;align-items: center;background-color: rgba(0, 0, 0, 0.5);border-radius: 4px;}
  .headImage .edit span{background-color: rgba(255, 255, 255, 0.36);margin-left:0;padding:4px 10px;border-radius:4px;font-weight: bold;color:#ffffff;cursor: pointer;}
  .headImage:hover .edit{display: flex;}
  .headImage img{width: 76px;height: 76px;border-radius: 4px;}
  .saveBtn{width: 130px;height: 42px;cursor: pointer;display: flex;align-items: center;justify-content: center;color: #ffffff !important;background-color: #5856D6;border-radius: 4px;box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);}
  .saveBtn i{margin-right: 10px;}
  @media (max-width: 768px) {
    .form .flex{grid-template-columns:1fr;gap: 20px;padding-bottom: 0;}
    .div{flex-direction: column;}
    .card{width: 100%;margin-left:0;}
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .form .flex{grid-template-columns:1fr;gap: 20px;padding-bottom: 0;}
    .card{width: 300px;}
  }
</style>
