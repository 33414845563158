import Vue from 'vue'
import Storage from 'vue-ls'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/permission'
import { Message, MessageBox, Notification, Loading, Icon, DatePicker,Rate,Tag,Dialog,Link, Progress, Checkbox, CheckboxButton, CheckboxGroup,
Select, Option, OptionGroup, Upload, Table, TableColumn, Pagination,Empty} from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false

const storageOptions = {
  namespace: 'social-marketing_',
  name: 'ls',
  storage: 'local',
}
Vue.use(Storage, storageOptions)

locale.use(lang)
Vue.use(Loading)
Vue.use(Icon)
Vue.use(Link)
Vue.use(Rate)
Vue.use(Tag)
Vue.use(Dialog)
Vue.use(DatePicker)
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Progress)
Vue.use(Select)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(Upload)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Empty)
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$message = Message
Vue.prototype.$messageBox = MessageBox
Vue.prototype.$notification = Notification
Vue.prototype.$loading = Loading

new Vue({
  render: h => h(App),
	router,
  store
}).$mount('#app')
