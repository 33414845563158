<template>
  <div id="home">

    <div class="div">
      <div class="title">
        Business Promoter
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{ fontSize: '14px', backgroundColor: '#F8F9FA',color:'#4A5468',fontWeight:'normal'}"
        :cell-style="{ fontSize: '14px'}"
        style="width: 100%;">
        <el-table-column
          min-width="200"
          prop="patientName"
          label="Patient Name">
        </el-table-column>
        <el-table-column
          prop="mobilePhone"
          min-width="200"
          label="Mobile Phone">
        </el-table-column>
        <el-table-column
          prop="email"
          min-width="200"
          sortable
          label="Email">
        </el-table-column>
        <el-table-column
          min-width="130"
          align="center"
          label="Click Throughs">
          <template slot-scope="scope">
            <i style="font-size: 18px;" class="el-icon-message"></i>
          </template>
        </el-table-column>
        <el-table-column
          min-width="130"
          align="center"
          label="Review Clicks">
          <template slot-scope="scope">
            <i style="font-size: 18px;" class="el-icon-s-platform"></i>
          </template>
        </el-table-column>
        <el-table-column
          min-width="180"
          sortable
          align="center"
          label="Marketing Opt In">
          <template slot-scope="scope">
            <i style="font-size: 18px;color: #5856D6;" class="el-icon-success"></i>
          </template>
        </el-table-column>
        <el-table-column
          min-width="120"
          sortable
          align="center"
          label="Refer">
          <template slot-scope="scope" style="display: flex;align-items: center;">
            <div class="label">
              <a>No</a> <i class="el-icon-warning-outline"></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="200"
          sortable
          label="Date">
          <template slot-scope="scope">
            07/28/2024 4:04 PM
          </template>
        </el-table-column>
      </el-table>
      <div class="pager">
        <el-pagination
          layout="prev, pager, next"
          :total="1000">
        </el-pagination>
      </div>
    </div>

  </div>
</template>
<script>
  import Vue from 'vue';
  export default {
    name: 'BusinessPromoter',
    components: {
    },
    data() {
      return {
        tableData: [{
          patientName: 'Alexander Marquez',
          mobilePhone: '(626) 665-1308-5262',
          email:'hyunwooeyi@gmail.com'
        }, {
          patientName: 'Alexander Marquez',
          mobilePhone: '(626) 665-1308-5262',
          email:'hyunwooeyi@gmail.com'
        }, {
          patientName: 'Alexander Marquez',
          mobilePhone: '(626) 665-1308-5262',
          email:'hyunwooeyi@gmail.com'
        }, {
          patientName: 'Alexander Marquez',
          mobilePhone: '(626) 665-1308-5262',
          email:'hyunwooeyi@gmail.com'
        }]
      }
    },
    computed: {

    },
    mounted() {

    },
    created(){

    },
    methods:{

    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;}
  #home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}
  .div{background-color: #ffffff;padding: 30px;display: grid;gap: 20px;box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);border-radius: 16px;}
  .title{color:#2D3648;font-size: 20px;font-weight: bold;display: flex;justify-content: space-between;align-items: center;}
  .label{display: flex;align-items: center;justify-content: center;}
  .label a{background-color: #FED7D7;color: #822727;width: 28px;height: 20px;font-size: 12px;display: flex;align-items: center;justify-content: center;border-radius: 6px;margin-right: 5px;}
  .pager{display: flex;justify-content: center;}

  @media (max-width: 768px) {
    .title{flex-direction: column;}
    .div{padding:20px 5px;}
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {

  }
</style>
