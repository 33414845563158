<template>
  <div id="home">
    <div class="account">
      <div class="item">
        <div>
          <a>
            <img src="../assets/imgs/icons/icon_pro6.png"/>
            Google
          </a>
          <div>
            <span>
              <i class="el-icon-user-solid"></i>
            </span>
            ieowi@google.com
          </div>
        </div>
        <h2 class="switch">Switch account</h2>
      </div>
      <div class="item">
        <div>
          <a>
            <img src="../assets/imgs/icons/icon_pro5.png"/>
            Yelp
          </a>
          <div>
            <h4>No account</h4>
          </div>
        </div>
        <h2>Link account</h2>
      </div>
    </div>
    <div class="filter">
      <h3>Filter</h3>
      <div class="item">
        <p>
          Month Pick
          <input value="May 2024"/>
        </p>
        <h2>Apply</h2>
      </div>
    </div>
    <div class="reputation">
      <h1>
        Jun 2024
      </h1>
      <div class="list">
        <div class="item">
          <img src="../assets/imgs/icons/icon_pro6.png"/>
          <div class="left">
            <h1>Google</h1>
            <p><b>67</b>Reviews</p>
            Based on 30 days
          </div>
          <div class="right">
            <a>Average Rating</a>
            <el-rate
              v-model="value" disabled>
            </el-rate>
            <span>4.5 / 5</span>
          </div>
        </div>
        <div class="item">
          <img src="../assets/imgs/icons/icon_pro5.png"/>
          <div class="left">
            <h1>Yelp</h1>
            <p><b>67</b>Reviews</p>
            Based on 30 days
          </div>
          <div class="right">
            <a>Average Rating</a>
            <el-rate
              v-model="value" disabled>
            </el-rate>
            <span>4.5 / 5</span>
          </div>
        </div>
      </div>
      <div class="reviews">
        <h2>Reviews</h2>
        <div>
          <div class="item">
            <h3>4</h3>
            <p>
              New Review
              <span>(Last 30 Days)</span>
            </p>
          </div>
          <div class="item">
            <h3>30</h3>
            <a>Total</a>
          </div>
        </div>
      </div>
      <div class="total">
        <div class="div">4.5<a>/5.0 Average rating</a></div>
        <el-rate :colors="['#5856D6','#5856D6','#5856D6']"
          v-model="4.5" disabled>
        </el-rate>
      </div>
      <div class="starList">
        <div class="item" v-for="(item,index) in 4" :key="index">
          <div class="left">
            <b>M</b>
            <p>
              Maudie
              <span>Itaque dolor fuga natus eveniet.</span>
            </p>
          </div>
          <div class="right">
            <img src="../assets/imgs/icons/icon_pro5.png"/>
            <div class="div">
              <el-rate v-model="4.0" disabled>
              </el-rate>
              4/5
              <a>
                Reply
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="all">
        <span>
          <i class="el-icon-right"></i>
        </span>
        <b>all review</b>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import { Rate } from 'element-ui';
  export default {
    name: 'ReputationReport',
    components: {

    },
    data() {
      return {
        value:4.5
      }
    },
    computed: {

    },
    created(){
      Vue.component(Rate.name, Rate);
    },
    methods:{

    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  #home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}
  .account{background-color: #ffffff;box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);border-radius: 16px;}
  .account .item{padding:20px;display: flex;align-items: center;}
  .account .item:first-child{border-bottom: 1px solid #EDF2F7;}
  .account .item a{display: flex;flex-direction: column;align-items: center;width: 60px;font-size: 12px;color: #717D96;flex-shrink: 0;}
  .account .item a img{width: 30px;height: 30px;}
  .account .item div{width: 100%;padding-left:15px;display: flex;align-items: center;font-size: 14px;color: #4A5468;}
  .account .item div span{width: 32px;height:32px;background-color: #A0AEC0;border-radius: 50%;margin-right: 10px;display: flex;justify-content: center;align-items: center;}
  .account .item div span i{font-size: 18px;color:#ffffff;}
  .account .item div h4{color:#A0ABC0;font-size: 14px;font-weight: normal;}
  .account .item h2{width: 180px;height: 40px;display: flex;justify-content: center;align-items: center;cursor: pointer;background-color: #5856D6;border-radius: 6px;color:#ffffff;font-size: 14px;font-weight: normal;}
  .account .item .switch{border:1px solid #5856D6;color:#5856D6;background-color: #ffffff;cursor: pointer;font-size: 14px;}
  .filter{background-color: #ffffff;box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);border-radius: 16px;padding: 20px 30px;}
  .filter h3{color:#2D3648;font-size: 20px;margin-bottom:30px;}
  .filter .item{display: flex;justify-content: space-between;align-items: flex-end;}
  .filter .item p{display: flex;flex-direction: column;color: #A0ABC0;}
  .filter .item input{width: 380px;height:36px;border-radius: 5px;border: 1px solid #EDF0F7;margin-top:10px;font-size: 14px;padding:0 20px;color:#717D96;}
  .filter .item h2{width: 80px;height: 40px;display: flex;justify-content: center;align-items: center;cursor: pointer;background-color: #5856D6;border-radius: 6px;color:#ffffff;font-size: 14px;font-weight: normal;}
  .reputation{display: flex;flex-direction: column;background-color: #ffffff;box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);border-radius: 16px;padding:20px;}
  .reputation h1{color:#5856D6;font-size: 20px;text-align: right;margin:0 20px 20px 0;font-weight: normal;}
  .reputation .list{display: grid;gap:30px;grid-template-columns: 1fr 1fr;}
  .reputation .list .item{height:134px;background-color: #ffffff;box-shadow: 0 1px 5px 0 rgba(0,0,0,0.1);border-radius: 16px;padding:0 20px;display: flex;align-items: center;}
  .reputation .list .item img{width:76px;height:76px;flex-shrink: 0;margin-right:15px;}
  .reputation .list .item .left{width: 100%;display: flex;flex-direction: column;color:#A0ABC0;font-size: 12px;}
  .reputation .list .item .left h1{font-size: 20px;color: #A0ABC0;text-align: left;margin:0;}
  .reputation .list .item .left p{font-size:14px;color:#717D96;margin-bottom:5px;}
  .reputation .list .item .left p b{font-size: 20px;color:#2D3648;margin-right: 10px;}
  .reputation .list .item .right{flex-shrink: 0;display: flex;align-items: center;flex-direction: column;}
  .reputation .list .item .right a{color:#A0AEC0;font-size: 12px;margin-bottom: 5px;}
  .reputation .list .item .right span{color:#4A5468;font-size: 14px;font-weight: bold;margin-top:5px;}
  .reviews{display: flex;justify-content: space-between;align-items: center;margin-top:30px;}
  .reviews h2{color:#2D3748;font-size: 20px;}
  .reviews div{display: flex;}
  .reviews .item{flex-direction: column;align-items: center;padding:0 20px;}
  .reviews .item:nth-child(1){border-right: 1px solid #CBD5E0;}
  .reviews .item h3{font-size: 40px;}
  .reviews .item p{display: flex;flex-direction: column;height: 36px;font-size: 14px;color: #717D96;align-items: center;}
  .reviews .item p span{font-size: 12px;}
  .reviews .item a{border-radius: 200px;background-color: #717D96;width: 100px;height: 36px;color: #ffffff;font-size: 16px;display: flex;align-items: center;justify-content: center;}
  .total{display: flex;align-items: center;font-size: 32px;color:#1A202C;font-weight: bold;}
  .total a{color: #5856D6;font-size: 18px;margin-left:10px;margin-right: 20px;}
  .total .div{display: flex;align-items: center;}
  .total div{display: flex;}
  .starList{display: grid;gap: 20px;margin-top:20px;}
  .starList .item{box-shadow: 0 1px 5px 0 rgba(0,0,0,0.1);border-radius: 16px;padding:20px;display: flex;}
  .starList .item .left{width: 100%;display: flex;}
  .starList .item .right{flex-shrink: 0;display: flex;}
  .starList .item b{width: 80px;height: 80px;background-color: #5D5DFF;font-size: 24px;color:#ffffff;display: flex;align-items: center;justify-content: center;margin-right: 10px;flex-shrink: 0;}
  .starList .item p{display: flex;flex-direction: column;width: 100%;color: #2D3648;font-size: 20px;font-weight: bold;}
  .starList .item p span{font-size: 18px;color: #717D96;font-weight: normal;}
  .starList .item img{width: 30px;height: 30px;flex-shrink: 0;margin-right: 10px;}
  .starList .item .div{display: flex;flex-direction: column;align-items: center;flex-shrink: 0;font-size: 12px;color: #4A5468;}
  .starList .item .div a{width: 100px;height: 36px;display: flex;align-items: center;justify-content: center;color: #5856D6;cursor: pointer;margin-top:5px;border:1px solid #5856D6;border-radius: 6px;font-size: 14px;}
  .all{display: flex;align-items: center;margin-top:20px;}
  .all span{width: 30px;height: 30px;display: flex;justify-content: center;cursor: pointer;align-items: center;background-color: #3E66DF;border-radius: 50%;margin-right: 10px;}
  .all span i{color:#ffffff;font-size: 20px;font-weight: bold;}
  .all b{cursor: pointer;color:#5856D6;font-size: 20px;}
  @media (max-width: 768px) {
    .reputation .list{gap:20px;grid-template-columns: 1fr;grid-template-rows: 1fr 1fr;}
    .account .item{flex-direction: column;}
    .filter .item input{width: 200px;}
    .starList .item p{font-size: 16px;}
    .starList .item p span{font-size: 14px;}
    .total{margin-top:20px;flex-direction: column;}
    .reputation .list .item img{width:50px;height:50px;}
    .reviews{flex-direction: column;}
    .reviews h2{align-self: flex-start;}
    .starList .item{flex-direction: column;}
    .starList .item .right{align-self: flex-end;}
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {

  }
</style>
