import Vue from 'vue'
import Router from 'vue-router'
import Home from 'views/Home'
import MonthlyAnalytics from 'views/MonthlyAnalytics'
import Seo from 'views/Seo'
import Support from 'views/Support'
import AccountSetting from 'views/AccountSetting'
import BusinessPromoter from 'views/BusinessPromoter'
import ReputationReport from 'views/ReputationReport'
import ReviewManagement from 'views/ReviewManagement'
import SocialMedia from 'views/SocialMedia'
import Login from 'views/Login'
import FindId from 'views/FindId'
import PasswordReset from 'views/Password-reset'
import FacebookLogin from 'views/FacebookLogin'
import CallInquiries from 'views/CallInquiries'
import ResetPassword from 'views/ResetPassword'


Vue.use(Router)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: Home,
    meta: { title: 'Dashboard' },
  },
  {
    path: '/monthlyAnalytics',
    component: MonthlyAnalytics,
    meta: { title: 'Reporting', childTitle: 'Monthly Analytics' },
  },
  {
    path: '/seo',
    component: Seo,
    meta: { title: 'Reporting', childTitle: 'SEO Report' },
  },
  {
    path: '/support',
    component: Support,
    meta: { title: 'Support' },
  },
  {
    path: '/accountSetting',
    component: AccountSetting,
    meta: { title: 'Settings', childTitle: 'Account Setting' },
  },
  {
    path: '/resetPassword',
    component: ResetPassword,
    meta: { title: 'Settings', childTitle: 'Reset Password' },
  },
  {
    path: '/businessPromoter',
    component: BusinessPromoter,
    meta: { title: 'Review', childTitle: 'Business Promoter' },
  },
  {
    path: '/reputationReport',
    component: ReputationReport,
    meta: { title: 'Reporting', childTitle: 'Reputation Report' },
  },
  {
    path: '/reviewManagement',
    component: ReviewManagement,
    meta: { title: 'Review', childTitle: 'Review Management' },
  },
  {
    path: '/callInquiries',
    component: CallInquiries,
    meta: { title: 'Call Inquiries'},
  },
  {
    path: '/socialMedia',
    component: SocialMedia,
    meta: { title: 'Reporting', childTitle: 'Social Media' },
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/findId',
    component: FindId
  },
  {
    path: '/password-reset/:code',
    component: PasswordReset
  },
  {
    path: '/facebookLogin',
    component: FacebookLogin
  },
  {
    path: '/404',
    component: () => import('views/exception/404')
  },
  {
    path: '*',
    redirect: '/404',
  }
]
const router = new Router({
  routes,
  mode: 'history',
  scrollBehavior(to,from,savePosition){
    if(savePosition){
      return savePosition
    } else {
      return {x:0,y:0}
    }
  }
})
export default router
